<template>
  <div id="Case">
    <h2>四川师范大学附属实验学校</h2>
    <!-- <p>Hejiang County， Jiangyang District， Luzhou City， Sichuan Province</p> -->
    <div class="case-box">
      <p class="pc">
        四川师范大学附属实验学校是“国家基础教育外语示范学校”，学校高度重视培养学生的综合能力，并探索出富有大学附属实验学校的发展特色的育人模式。
      </p>
      <p class="pc">
        初中部全校统一使用学业质量管理平台3.0，支持各种考试与日常作业数据的常态化采集，通过学情大数据分析帮助学生查漏补缺，为教师提供精准学情分析、薄弱知识点分析、错题整理和归纳，进而达成科学高效的教学目标。
      </p>
      <p class="pc">
        以师生并进、共同成长为核心目标，帮助学校提高考试效率，改进考后评价，有效利用大数据帮助教师设计作业，建立校本作业体系，落实分层作业、弹性作业和个性化作业。切实减轻了学生的课业负担，提升教师的教学效能，全面贯彻落实国家“双减”政策。
      </p>
      <p class="mobile">
        四川师范大学附属实验学校是“国家基础教育外语示范学校”，学校高度重视培养学生的综合能力，并探索出富有大学附属实验学校的发展特色的育人模式。
      </p>
      <p class="mobile">
        初中部全校统一使用学业质量管理平台3.0，支持各种考试与日常作业数据的常态化采集，通过学情大数据分析帮助学生查漏补缺，为教师提供精准学情分析、薄弱知识点分析、错题整理和归纳，进而达成科学高效的教学目标。
      </p>
      <p class="mobile last">
        以师生并进、共同成长为核心目标，帮助学校提高考试效率，改进考后评价，有效利用大数据帮助教师设计作业，建立校本作业体系，落实分层作业、弹性作业和个性化作业。切实减轻了学生的课业负担，提升教师的教学效能，全面贯彻落实国家“双减”政策。
      </p>
      <img src="@/assets/images/partnersCase/regionalCase_bg_7.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    window.scrollTo(0, 1)
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 180px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 60px auto 0;
    width: 90%;
    max-width: 1320px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
      margin: 0;
    }
    img {
      width: 70%;
      margin-top: 80px;
    }
  }
}

.view-pc > #Case {
  @extend %Case;
  @media screen and (max-width: 1200px) {
    .case-box {
      width: 90%;
      img {
        width: 100%;
      }
    }
  }
}

.view-mobile > #Case {
  @extend %Case;
  h2 {
    font-size: 22px;
  }
  .case-box {
    width: 90%;
    margin-top: 30px !important;
    p {
      font-size: 14px;
      line-height: 28px;
      // margin: 30px 0;
    }
    .mobile {
      margin-bottom: 14px;
      &:first-child {
        margin-top: 30px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .last {
      margin-bottom: 0;
    }
  }
  margin-top: 44px;
  padding-top: 15px;
  > p {
    font-size: 14px;
    &::after {
      left: 42%;
      margin-top: 35px;
    }
  }
  .case-box {
    margin-top: 80px;
    img {
      width: 100%;
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
